<template>
  <div class="m-flex-col">
    <div>
      <info-box></info-box>
    </div>
      <goods-info></goods-info>
      <order-info></order-info>
  </div>
</template>

<script>
import { msgErr, msgSuc } from "@/helpers/message";

import { cloneDeep } from "lodash";
import Sortable from "sortablejs";
import InfoBox from "./infoBox";
import GoodsInfo from "./goodsInfo";
import OrderInfo from "./orderInfo";


export default {
  components: { InfoBox, GoodsInfo,OrderInfo },
  props: {
    mode: {
      type: String,
      default: "view" // view-页面；component-组件
    }
  },
  data() {
    return {
    };
  },
  emits: ["choose"],
  created() {},
  mounted() {
    this.initPage();
  },
  activated() {
    
  },
  methods: {
    initPage(){

    }
  }
};
</script>

<style lang="scss" scoped></style>
