
<template>
  <div class="m-flex container m-row-between">
    <div
      v-for="(dataInfo,index) in dataList"
      :key="index"
      class="m-flex panel"
      :style="dataInfo.style"
    >
      <div class="panel-body">
        <div class="title">
          <div>{{dataInfo.title}}</div>
        </div>
        <div class="content">
          <div class="value">{{dataInfo.value}}</div>
          <div class="label">
            <i :class="dataInfo.icon"></i>
            <small>{{dataInfo.label}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listByPage as listCategory } from "@/apis/unidrink/category";
import { listByPage as listOrder } from "@/apis/unidrink/order";
import { getProductList as listProduct } from "@/apis/unidrink/product";
import { listByPage as listShop } from "@/apis/unidrink/shop";
import { listByPage as listChannel } from "@/apis/unidrink/channel";
import validators from "@/helpers/validator";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: "add" // add-新增；edit-编辑
    },
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataList:[
        {
          title:"订单统计",
          value:"0",
          label:"当前有效订单记录量",
          icon:"fa fa-print",
          style:`background:-webkit-gradient(linear, left bottom, left top, color-stop(0, #18bc9c), color-stop(1, #1cdcb6))`
        },
        {
          title:"门店统计",
          value:"0",
          label:"当前门店总记录量",
          icon:"fa fa-user",
          style:`background:-webkit-gradient(linear, left bottom, left top, color-stop(0, #3498db), color-stop(1, #52a7e0))`
        },
        {
          title:"渠道统计",
          value:"0",
          label:"当前渠道总记录量",
          icon:"fa fa-qrcode",
          style:`background:-webkit-gradient(linear, left bottom, left top, color-stop(0, #605ca8), color-stop(1, #9491c4))`
        },
        {
          title:"产品统计",
          value:"0",
          label:"当前产品总记录量",
          icon:"fa fa-product-hunt",
          style:`background:-webkit-gradient(linear, left bottom, left top, color-stop(0, #605ca8), color-stop(1, #0073b7))`
        },
        {
          title:"分类统计",
          value:"0",
          label:"当前分类总记录量",
          icon:"fa fa-align-justify",
          style:`background:-webkit-gradient(linear, left bottom, left top, color-stop(0, #f39c12), color-stop(1, #f7bc60))`
        },
      ],
      serviceList:[
        listOrder,
        listShop,
        listChannel,
        listProduct,
        listCategory
      ],
      pageForm: {
        pageSize: 10,
        currPage: 1
      }
    };
  },
  emits: ["update:modelValue"],
  created() {
  },
  mounted(){
    this.initData();
  },
  watch: {
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
    initData(){
      for(let i=0;i<this.serviceList.length;i++){
        this.commonList(i);
      }
    },
    commonList(index){
      this.serviceList[index](this.pageForm)
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { total } = result;
          this.dataList[index].value = total;
        })
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 80%;
  margin: 0 20px;
}
.panel {
  min-height: 150px;
  min-width: 50px;
  margin-top: 30px;
  width: 16%;
  color: #fff;
  .panel-body {
    width: 100%;
    padding: 15px;
    .title {
      font-size: 20px;
      margin: 10px 0;
      padding:0;
    }
    .content {
      .label {
        margin-top: 10px;
        font-size: 16px;
      }
      .value {
        font-size: 20px;
      }
    }
  }
}
</style>
